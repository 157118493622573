// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAMTiWbe8Hrhrp3nx4xv86x8hzGQm7Sb6E",
    authDomain: "seatr2.firebaseapp.com",
    databaseURL: "https://seatr2-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "seatr2",
    storageBucket: "seatr2.appspot.com",
    messagingSenderId: "451383212455",
    appId: "1:451383212455:web:cf67415cf6a2de613c5e82"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
